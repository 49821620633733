function counterAnimationHandler() {
    const counters = document.querySelectorAll(".inview .counter");
    counters.forEach(counter => {
        counter.innerText = '0' //set default counter value
        counter.dataset.count = 0;
        const updateCounter = () => {
            const target = +counter.getAttribute('data-target') //define increase couter to it's data-target
            const count = +counter.dataset.count //define increase couter on innerText

            //const increment = target / 200 // define increment as counter increase value / speed
            const increment = +counter.getAttribute('data-increment')
            const speed = +counter.getAttribute('data-speed')
            if (count < target) {
                const newCount = Math.ceil(count + increment);
                counter.dataset.count = newCount;
                counter.innerText = numberWithCommas(newCount);
                setTimeout(updateCounter, speed);
            } else {
                counter.innerText = numberWithCommas(target); //if default value is bigger that date-target, show data-target
            }
        }

        updateCounter() //call the function event
    })

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}

/*
window.addEventListener('scroll', (event) => {
    counterAnimationHandler();
}), { passive: true };
*/


// Get the an HTML Element
var counterElement = document.querySelector('.counter');
if (counterElement) {
    // Get its bounding client rectangle
    var bounding = counterElement.getBoundingClientRect();

    function isInViewport(counterElement) {
        // Get the bounding client rectangle position in the viewport
        var bounding = counterElement.getBoundingClientRect();

        // Checking part. Here the code checks if it's *fully* visible
        // Edit this part if you just want a partial visibility
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <=
            (window.innerWidth || document.documentcounterElement.clientWidth) &&
            bounding.bottom <=
            (window.innerHeight || document.documentcounterElement.clientHeight)
        ) {
            //console.log("In the viewport! :)");
            return true;
        } else {
            //console.log("Not in the viewport. :(");
            return false;
        }
    }

    // funktion wurde nicht ausgeführt
    var executed = false;
    //console.log(executed);
    window.addEventListener(
        "scroll",
        function(event) {
            if (isInViewport(counterElement) && !executed) {
                counterAnimationHandler();
                // funktion wurde ausgeführt
                executed = true;
                //console.log(executed);
            }
        },
        false
    );
}