function clickedImg(e) {
    e.preventDefault();

    if (this.classList.contains("active")) {
        this.classList.remove("active");
        // Style von .desc entfernen
        this.querySelector(".desc").removeAttribute("style");
    } else {
        const elements = document.querySelectorAll(".icon");
        elements.forEach((element) => {
            element.classList.remove("active");
            // Style von .desc entfernen
            element.querySelector(".desc").removeAttribute("style");
        });
        this.classList.toggle("active");
        // Höhe des Elements ermitteln
        let height = this.offsetHeight;
        // Div mit .desc suchen
        let desc = this.querySelector(".desc");
        // .desc Höhe als top-Wert zuweisen
        desc.style.top = height + 'px';
    }
}

const mediaQuery = window.matchMedia("(min-width: 1200px)");
if (mediaQuery.matches) {
    document.querySelectorAll(".icon").forEach(function(el) {
        el.addEventListener("mouseover", clickedImg);
        el.addEventListener("mouseout", clickedImg);
        el.addEventListener("click", clickedImg);
    });
}