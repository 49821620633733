// set class to element in viewport
const inViewport = (elem) => {
    let allElements = document.getElementsByClassName("waypoint");

    let windowHeight = window.innerHeight;
    const elems = () => {
        for (let i = 0; i < allElements.length; i++) {
            //  loop through the sections
            let viewportOffset = allElements[i].getBoundingClientRect(); //  returns the size of an element and its position relative to the viewport
            let top = viewportOffset.top; //  get the offset top
            if (top < windowHeight) {
                //  if the top offset is less than the window height
                allElements[i].classList.add("inview"); //  add the class
            } else {
                allElements[i].classList.remove("inview"); //  remove the class
            }
        }
    };
    elems();
    window.addEventListener("scroll", elems), {
        passive: true
    };
};
inViewport("section"); //  run the function on all section elements

// hamburger vanilla
var el = document.querySelector('#menuToggle');
var nav = document.querySelector('#nav');
var bur = document.querySelector('.hamburger');
if (el) {
    el.onclick = function() {
        bur.classList.toggle('is-active');
        nav.classList.toggle('active');
    }
}

// accordion
const accordions = document.querySelectorAll(".accordion");

const openAccordion = (accordion) => {
    const content = accordion.querySelector(".accordion__content");
    accordion.classList.add("accordion__active");
    //content.style.maxHeight = content.scrollHeight + "px";
    content.style.maxHeight = content.scrollHeight + 130 + "px"; // add 2x 65 padding of content
    //this.style.maxHeight = content.clientHeight + "px";
};

const closeAccordion = (accordion) => {
    const content = accordion.querySelector(".accordion__content");
    accordion.classList.remove("accordion__active");
    content.style.maxHeight = null;
};

accordions.forEach((accordion) => {
    const intro = accordion.querySelector(".accordion__intro");
    const content = accordion.querySelector(".accordion__content");

    intro.onclick = () => {
        if (content.style.maxHeight) {
            closeAccordion(accordion);
        } else {
            accordions.forEach((accordion) => closeAccordion(accordion));
            openAccordion(accordion);
        }
    };
});

// Tabcordion mobil schliessen
const mediaQueryTwo = window.matchMedia("(max-width: 915px)");
if (mediaQueryTwo.matches) {
    const accord = document.getElementById('tab1-tab');
    accord.classList.remove("is-active");
}